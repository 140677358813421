import React from 'react'

import { Layout } from '../../components/auth/Layout'
import { SignUpContent } from '../../components/auth/SignUpContent'
import { AccountType } from '../../requests'

const IndexPage = () => {
  return (
    <Layout accountType={AccountType.Subject} isWider hasCustomTranslations>
      <SignUpContent accountType={AccountType.Subject} isQrUsed />
    </Layout>
  )
}

export default IndexPage
export const Head = () => <title>Datacapt - Register</title>
